import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { BsWhatsapp } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
export default function ScrollToTop() {
  const [scrollState, setScrollState] = useState(false);
  const toTop = () => {
    window.scrollTo({ top: 0 });
  };

  window.addEventListener("scroll", () => {
    window.pageYOffset > 200 ? setScrollState(true) : setScrollState(false);
  });
  return (
    <>
    <ToTop onClick={toTop} scrollState={scrollState}>
      <img src={logo} alt="" />
      
    </ToTop>

    <ConnectOP>
      <div class="i1">
        <a target="_blank" rel="noreferrer" href="mailto:singhnegi186@gmail.com">
          <GoMail />
        </a>
        </div>
        <div class="i2">
        <a target="_blank" rel="noreferrer" href="https://wa.me/916398563598?text=Hi%2C%0AI%20want%20to%20know%20about%20a%20tour%20package%20provided%20in%20the%20site">
          <BsWhatsapp />
        </a>
        </div>
        <div class = "i3">
        <a target="_blank" rel="noreferrer" href="tel:916398563598">
          <FiPhoneCall />
        </a>
        </div>
    </ConnectOP>
    </>

  );
}

const ToTop = styled.div`
  display: ${({ scrollState }) => (scrollState ? "block" : "none")};
  position: fixed;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  z-index: 1;
  bottom: 22%;
  right: 1%;
  img {
    height: 1.5rem;
  }
  border-radius: 2rem;
  background-color: #1900ff30;
  padding: 0.5rem;
`;

const ConnectOP = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  bottom: 1%;
  right: 1%;
  height: 9rem;
  
  .i1 {
    border-radius: 2rem;
    margin-bottom: 1.5rem;
    svg {
      fill: #DB4437;
    }
  }
  .i2 {
    border-radius: 2rem;
    margin-bottom: 1.4rem;
    bottom: 6rem;
    svg {
      fill: #25D366;
    }
  }
  .i3 {
    border-radius: 2rem;
    bottom:9rem;  
  }
  svg {
    font-size: 1.5rem;
    &:hover {
      color: #FF0000;
    }
  }
  
  border-radius: 2rem;
  background-color: #1900ff30;
  padding: 0.5rem;

`;
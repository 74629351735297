import React from "react";
import styled from "styled-components";
import homeImage from "../assets/hero.jpg";



export default function Hero(props) {
  return (
    <Section id="hero">
      <div className="background">
        <img src={homeImage} alt="" />
      </div>
      <div className="content">
        <div className="title">
          <h1>PARADISE ON EARTH</h1>
          <p>
            Explore Uttarakhand - Paradise On Earth, From lands of Rishikesh to heights of Kedarnath through Tungnath and Valley of Flowers rich in Flora and Fauna with our exclusive packages.
          </p>
        </div>
        <div className="search">
          <form onSubmit={(event)=>{event.preventDefault(); window.location="#recommend" ; props.setsearchterm(document.getElementsByTagName("input")[0].value);}}>
          <div className="container">
            <input type="text" style={{width: "300px"}} placeholder="Search Your location" />
          </div>
          {/*<div className="container">
            <label htmlFor="">Check-in</label>
            <input type="date" />
          </div>
          <div className="container">
            <label htmlFor="">Check-out</label>
            <input type="date" />
          </div>
  */}
          <button type="submit">Explore Now</button>
        </form>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  padding: 0rem;


  .background {
    height: 100%;
    img {
      width: 100%;
      filter: brightness(60%);
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffff9a;
      padding: 1rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="text"] {
            padding: 1rem;
            font-size: 1.2rem;
          }

          &::placeholder {
            font-size: 1.1rem;
            height: 10rem;
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        bottom: 1rem;
        background-color: #4361ee;
        font-size: 0.8rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 23rem;
    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1.1rem;
        }
        p {
          font-size: 0.9rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 1rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="text"] {
            padding: 1rem;
            font-size: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;

import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Recommend from "./components/Recommend";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import scrollreveal from "scrollreveal";
//import Destination1 from "./assets/Destination1.png";
import char_dham from "./assets/4_dham.png"
import do_dham from "./assets/2_dham.jpg";
import kedarnath from "./assets/kedarnath.jpg";
import panchKedar from "./assets/panch_kedar.jpg";
import haridwarRishikesh from "./assets/haridwar_rishikesh.jpg";
import kedarkanth from "./assets/kedarkantha.jpg";
import rafting from "./assets/rafting.jpg";
import bhramtal from "./assets/bhramatal.jpg";
import dyaraBugyal from "./assets/dayara-bugyal.jpg";
import valleyFlower from "./assets/valley_flowers2.jpeg";
import roopkund from "./assets/Roopkund_Lake.jpg";
import vasukital from "./assets/vasuki-tal.jpg";
import chandrashila from "./assets/chandrashila.jpg";
import deoriyatal from "./assets/devoria-tal.jpg";
import nainital from "./assets/nainital.jpg";
import JimCorbett from "./assets/jim_corbett.jpg";
import mussouri from "./assets/mussoorie2.jpg";
import rishikesh from "./assets/haridwar_rishikesh2.jpg";
import therilake from "./assets/tehri_lake.jpg";
import kausani from "./assets/kausani.jpg";


export default function App() {

  const [searchterm, setsearchterm] = useState("");



  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 1000,
      reset: true,
    });
    sr.reveal(
      `
        nav,
        #hero,
        #services,
        #recommend,
        #testimonials,
        footer
        `,
      {
        opacity: 80,
        interval: 200,
      }
    );
  }, []);


  const data = [
    {
      image: char_dham,
      title: "4 Dham Yatra",
      subTitle: "4 Dham Yatra Package include 4 destinations of chota char dham: Kedarnath - Badrinath - Yamnotri - Gangotri",
      type: 1,
      duration: "9 Night 10 Day",
    },
    {
      image: do_dham,
      title: "2 Dham Yatra",
      subTitle: "2 Dham Yatra Package include 2 main pilgrimage of chota char dham: Kedarnath - Badrinath",
      type: 1,
      duration: "4 Night 5 Day",
    },
    {
      image: kedarnath,
      title: "Kedarnath Tour",
      subTitle: "Kedarnath Trek starts from sonprayag in the morning and after 17 km you're with Kedar Baba",
      type: 1,
      duration: "2 Night 3 Day",
    },
    {
      image: panchKedar,
      title: "Panch Kedar",
      subTitle: "Kedarnath Temple at an altitude of 3,583 m (11,755 ft), the Tungnath Temple(3,680 m or 12,070 ft), the Rudranath Temple(3,559 m or 11,677 ft), the Madhyamaheshwar Temple(3,490 m or 11,450 ft) and the Kalpeshwar Temple(2,200 m or 7,200 ft)",
      type: 1,
      duration: "10 Night 11 Day",
    },
    {
      image: haridwarRishikesh,
      title: "Haridwar-Rishikesh",
      subTitle: "Feel the holy environment of Haridwar and Rishikesh with the glorious evening aarti.",
      type: 1,
      duration: "2 Night 3 Day",
    },

    {
      image: kedarkanth,
      title: "Kedar Kanth Trek",
      subTitle: "Trek To Experience Stunning Panoramic Views from the Kedarkantha Peak - Best Snow Trek",
      type: 2,
      duration: "6 Night 7 Day",
    },
    {
      image: rafting,
      title: "Rishikesh River Rafting",
      subTitle: "Bestowed with the Ganga and its tributaries and some of the deep ravines, Rishikesh river rafting promises endless enthralling moments with best adrenaline rush to the rafter",
      type: 2,
      duration: "2 Night 3 Day",
    },
    {
      image: bhramtal,
      title: "Bhramatal Trek",
      subTitle: "Devoted to Lord Brahma, a perfect blend of adventure and serenity. trek around 5-6 km each day.",
      type: 2,
      duration: "6 Night 7 Day",
    },
    {
      image: dyaraBugyal,
      title: "Dyara Bugyal Trek",
      subTitle: "The breathtaking alpine meadow of Dayara Bugyal reigns atop a staggering 12,000 ft, against a stunning canvas of lofty Himalaya. Dayara Bugyal Trek lies in the Uttarkashi district of Uttarakhand.",
      type: 2,
      duration: "3 Night 4 Day",
    },
    {
      image: valleyFlower,
      title: "Valley Of Flowers",
      subTitle: "Bestowed with the rare and exotic Himalayan flora, the Valley of Flowers is a bouquet of nature peacefully snuggled in the West Himalayan region of Uttarakhand.",
      type: 2,
      duration: "6 Night 7 Day",
    },
    {
      image: roopkund,
      title: "Roopkund Trek",
      subTitle: "Roopkund is a high altitude glacial lake in the Uttarakhand state of India. It lies in the lap of Trishul massif. Located in the Himalayas",
      type: 2,
      duration: "7 Night 8 Day",
    },
    {
      image: vasukital,
      title: "Vasuki Taal Trek",
      subTitle: "Vasuki Tal or Vasuki Taal is a high glacial lake situated at an impressive altitude of 14,200 feet above sea level in Kedarnath Dham of Uttarakhand.",
      type: 2,
      duration: "3 Night 4 Day",
    },
    {
      image: chandrashila,
      title: "Chopta-Chandrashila Trek",
      subTitle: "Chandrashila means the ‘Moon Rock’ is the summit of the Chandranath Parvat on which the Tungnath temple of Panch Kedar is situated. The majestic Chandrashila summit serves as a vantage point that offers a heavenly panorama of the Great Himalayas.",
      type: 2,
      duration: "2 Night 3 Day",
    },

    {
      image: deoriyatal,
      title: "Chopta - Chandrashila - deoriyatal",
      subTitle: "Deoriatal Chopta Chandrashila trek is an 'All In One' Himalayan trek - A hidden lake with massive reflections, Tunganath shrine and summit to Chandrashila.",
      type: 3,
      duration: "4 Night 5 Day",
    },
    {
      image: nainital,
      title: "Jim Corbett - Nainital",
      subTitle: "Nainital is a glittering jewel in the Himalyan necklace, blessed with scenic natural spledour and varied natural resources, Dotted with lakes and The Jim Corbett National Park is a part of the largest Corbett Tiger Reserve.",
      type: 3,
      duration: "6 Night 7 Day",
    },

    {
      image: JimCorbett,
      title: "Jim Corbett Delux",
      subTitle: "The Jim Corbett National Park is a part of the largest Corbett Tiger Reserve; the Project Tiger lies in the Nainital district of Uttarakhand.",
      type: 4,
      duration: "2 Night 3 Day",
    },
    {
      image: mussouri,
      title: "Dehradun - Mussoorie",
      subTitle: "Mussoorie, also known as Queen of the Hills, is among the most popular hill stations of the country. Famous as weekend getaway and honeymoon destination.",
      type: 4,
      duration: "2 Night 3 Day",
    },
    {
      image: rishikesh,
      title: "Dehradun - Mussoorie - Rishikesh",
      subTitle: "Dehradun to Mussoorie, Queen of the Hills to Rishikesh, the Yoga capital",
      type: 4,
      duration: "3 Night 4 Day",
    },
    {
      image: therilake,
      title: "Dehradun - Mussoorie - Dhanaulti - Tehri Lake",
      subTitle: "Perched at an elevation of 1,700 meters, Tehri Lake is an artificial dam reservoir that came into existence during the construction of Tehri Dam.",
      type: 4,
      duration: "6 Night 7 Day",
    },
    {
      image: kausani,
      title: "Nainital - Almora - Kausani",
      subTitle: "Perched on a ridge covered with chir and blue pine forests, is most well-known for the spectacular panoramic view of the Himalayan peaks like Trishul, Nanda Devi, and the mighty Panchachuli. Enjoy a picture-perfect sunrise from one of the many viewing platforms in the village.",
      type: 4,
      duration: "3 Night 4 Day",
    },
  ];

  const packages = [
    "Char Dham",
    "Trekking & Adventure Sports",
    "Taal/Lake",
    "Honeymoon/Work from anywhere Destination"
  ];  

  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Hero setsearchterm={setsearchterm} />
      <Services />
      <Recommend searchterm={searchterm} data={data} packages={packages} />
      <Testimonials />
      <Footer />
    </div>
  );
}
